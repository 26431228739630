import GATSBY_COMPILED_MDX from "/opt/build/repo/content/category/backwashes.mdx";
import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/layout';
import Header from '../components/header';
import Seo from '../components/seo';
import Container from '../components/container';
import AnimationWrapper from '../components/animationWrapper';
import DescriptionSection from '../components/descriptionSection';
import LightboxImageGallery from '../components/lightboxImageGallery';
import DownloadSection from '../components/downloadSection';
import ContactSection from '../components/contactSection';
import './category-page.css';
const CategoryPage = ({data, location, children}) => {
  return React.createElement(Layout, {
    location: location
  }, React.createElement(Seo, {
    title: `${data.category.frontmatter.name}`
  }), React.createElement(AnimationWrapper, null, React.createElement(Header, {
    pageName: data.category.frontmatter.name,
    data: data.header
  })), React.createElement("section", {
    id: "category-desc",
    className: "page-section"
  }, data.category.frontmatter.description_sections.map((description_section, key) => React.createElement(DescriptionSection, {
    key: key,
    location: "oferta",
    data: description_section
  }))), React.createElement("section", {
    id: "products-gallery",
    className: "gallery"
  }, React.createElement(Container, null, React.createElement(LightboxImageGallery, {
    data: data.category.frontmatter.products_gallery
  }))), data.download.nodes[0] && React.createElement(DownloadSection, {
    data: data.download,
    location: "Do pobrania"
  }), React.createElement("section", {
    id: "contact-data",
    className: "page-section"
  }, React.createElement(ContactSection, {
    data: data.category.frontmatter.contact_section
  })));
};
export const query = graphql`
  query(
    $slug: String,
    $headerPath: String,
  ) {
    category: mdx(frontmatter: { slug: { eq: $slug } }) {
        frontmatter {
          name
          slug
          meta_description
          description_sections{
            title
            title_visible
            subtitle
            align
            text
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
            object_fit
            button_text
            button_url
            button_style
          }
          products_gallery{
            title
            title_visible
            type
            button_text
            button_url
            button_style
            photos{
              title
              description
              image{
                childImageSharp {
                  gatsbyImageData(
                    transformOptions: {fit: COVER},
                    webpOptions: {quality: 95}
                  )
                }
              }
              status
            }
          }
          contact_section{
            title
            description
            type
            fields
            submit_button_style
            image{
              childImageSharp{
                gatsbyImageData(
                  webpOptions: {quality: 95}
                )
              }
            }
          }
        }
        internal {
          contentFilePath
        }
      }
      header: file(relativePath: {eq: $headerPath}) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
            quality: 95
            transformOptions: {fit: COVER}
            webpOptions: {quality: 95}
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      download: allMdx(
        sort: {fields: [frontmatter___order], order: ASC}
        filter: {
          frontmatter: {
            connections: {eq: $slug },
            type: {eq: "download"}
          }
        }
      ) {
        nodes {
          frontmatter {
            name
            file {
              publicURL
            }
          }
          id
        }
      }
  }
`;
CategoryPage
export default function GatsbyMDXWrapper(props) {
  return React.createElement(CategoryPage, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
